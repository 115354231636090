import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M2490 3910 c0 -5 5 -10 10 -10 6 0 10 -9 10 -21 0 -14 -5 -19 -17
-17 -11 2 -18 12 -17 26 0 19 -4 22 -40 22 -115 0 -329 -60 -470 -131 -179
-90 -380 -266 -486 -423 -125 -187 -202 -405 -217 -617 -6 -86 -5 -89 16 -89
25 0 30 37 6 46 -19 8 -19 34 0 34 9 0 15 9 15 25 0 14 -4 25 -10 25 -19 0
-10 28 10 33 23 6 27 35 5 43 -22 9 -18 34 5 34 21 0 29 33 10 45 -17 10 -12
35 6 28 16 -6 34 12 34 35 0 6 -7 12 -15 12 -8 0 -15 7 -15 15 0 9 9 15 25 15
31 0 45 28 22 42 -17 9 -24 38 -9 38 12 0 29 -14 34 -29 2 -8 17 16 33 53 28
65 28 69 11 82 -22 15 -12 37 14 29 26 -8 44 25 23 41 -22 15 -7 35 21 28 26
-6 46 24 25 37 -7 4 -9 15 -5 24 5 14 9 15 22 4 12 -10 18 -9 31 4 14 13 14
19 3 32 -9 11 -10 19 -3 26 8 8 13 7 17 0 10 -16 33 -13 40 5 4 9 1 23 -5 30
-17 20 -2 36 22 23 27 -14 55 6 38 28 -9 11 -9 17 1 26 10 9 13 9 17 0 6 -16
25 -17 41 -1 8 8 8 17 1 30 -14 26 -3 35 22 20 28 -18 53 1 39 28 -12 23 5 37
24 21 22 -18 48 -2 42 25 -7 28 11 42 27 19 18 -24 48 -9 41 20 -7 28 11 42
24 19 5 -9 18 -14 28 -12 14 3 18 11 16 31 -3 30 21 38 36 12 14 -25 45 -7 38
21 -5 19 -2 24 14 24 11 0 20 -7 20 -16 0 -10 7 -14 23 -12 18 2 21 8 19 31
-4 33 23 38 33 6 10 -30 40 -20 40 14 -1 33 30 38 38 6 5 -20 10 -21 64 -15
155 17 270 18 383 2 63 -9 124 -20 135 -25 11 -5 42 -15 70 -21 72 -17 257
-108 350 -173 44 -30 116 -89 160 -130 61 -58 83 -73 93 -65 20 17 39 -6 22
-27 -18 -22 5 -49 30 -35 13 7 21 5 30 -5 9 -11 7 -16 -10 -25 -17 -9 -19 -14
-10 -26 7 -8 21 -12 35 -8 27 7 42 -21 19 -34 -24 -13 -7 -43 21 -36 27 7 42
-21 19 -34 -21 -12 -7 -44 16 -37 11 4 20 -1 24 -12 4 -10 2 -19 -3 -21 -21
-7 -12 -42 10 -42 19 0 20 -4 15 -35 -5 -27 -4 -35 7 -35 17 0 30 -37 16 -45
-11 -8 0 -35 15 -35 15 0 17 -32 3 -41 -6 -4 -6 -15 -2 -25 13 -31 15 -45 20
-139 10 -176 11 -235 3 -235 -14 0 -8 -28 7 -34 22 -8 18 -37 -5 -43 -15 -4
-19 -12 -16 -36 2 -17 -3 -37 -10 -45 -16 -15 -18 -32 -5 -32 5 0 7 -9 4 -20
-3 -11 -11 -20 -18 -20 -7 0 -15 -9 -18 -20 -3 -11 -1 -20 4 -20 5 0 9 -7 9
-15 0 -8 -7 -15 -15 -15 -8 0 -16 -6 -17 -12 0 -7 -2 -17 -3 -23 -2 -5 -3 -18
-4 -28 0 -10 -5 -16 -10 -13 -13 9 -23 -25 -11 -39 13 -16 2 -45 -14 -39 -7 3
-21 -6 -32 -18 -10 -13 -15 -20 -10 -16 5 4 15 3 23 -3 21 -18 -3 -42 -28 -28
-14 7 -19 6 -19 -2 0 -6 -12 -25 -26 -42 -15 -18 -24 -38 -20 -50 2 -10 2 -25
-1 -33 -7 -20 -23 -7 -16 14 3 9 -8 1 -25 -18 -16 -19 -39 -45 -50 -58 -12
-13 -18 -31 -15 -42 3 -12 0 -20 -7 -20 -7 0 -10 7 -6 15 3 8 4 15 3 15 -1 0
-32 -27 -67 -60 l-65 -61 -100 7 c-55 3 -119 13 -143 21 -31 10 -63 12 -111 7
-51 -5 -70 -3 -76 6 -5 9 -4 11 3 6 7 -4 12 -2 12 4 0 6 5 8 10 5 6 -3 10 -1
10 5 0 6 5 8 11 4 8 -4 9 1 5 17 -6 20 -5 21 4 7 10 -17 13 -17 41 -2 21 10
29 20 25 31 -6 14 -4 14 11 2 15 -13 16 -12 9 6 -6 16 -5 17 5 8 17 -17 73 12
65 34 -10 25 4 36 24 18 16 -15 18 -15 24 0 3 9 3 22 0 28 -5 9 -4 9 6 0 13
-12 43 -6 35 7 -3 5 4 11 15 15 11 4 18 10 15 15 -3 5 4 11 15 15 11 4 18 10
15 15 -3 5 4 12 15 15 17 5 19 9 9 21 -10 12 -9 13 3 9 9 -4 27 4 41 18 25 22
25 24 9 42 -16 18 -16 18 2 5 16 -12 20 -12 30 0 6 8 20 20 30 25 24 14 94
141 98 180 2 23 -1 30 -17 33 -17 3 -18 1 -5 -8 10 -8 11 -10 2 -8 -23 6 -12
23 14 21 30 -2 31 5 2 18 -21 9 -21 9 5 4 15 -3 27 -1 27 4 0 5 3 16 6 24 3
10 -4 19 -23 26 l-28 11 28 -4 c15 -2 30 1 33 5 3 5 -6 9 -20 9 -21 0 -26 5
-26 25 0 19 5 25 23 24 20 0 21 -1 3 -8 -19 -7 -19 -8 2 -13 27 -7 28 -7 41
66 32 173 0 402 -82 578 -60 131 -152 258 -176 243 -10 -6 -10 -9 -1 -15 9 -5
9 -9 1 -14 -6 -4 -12 -1 -12 6 -1 7 -2 20 -3 28 0 8 -9 14 -18 14 -11 -1 -15
4 -11 15 4 9 3 13 -1 10 -4 -4 -22 7 -41 25 -47 44 -69 61 -73 58 -2 -2 -9 4
-16 14 -8 11 -18 14 -26 9 -8 -5 -11 -4 -7 2 6 10 -50 40 -65 35 -4 -1 -4 6
-1 15 4 10 2 14 -4 10 -6 -4 -19 0 -27 9 -9 8 -16 13 -16 10 0 -3 -20 4 -45
15 -25 11 -50 17 -55 14 -6 -3 -10 -2 -10 4 0 11 -79 36 -145 47 -51 8 -70 12
-70 14 0 1 -26 4 -58 5 -54 3 -58 1 -54 -16 3 -10 1 -19 -4 -19 -5 0 -9 8 -9
19 0 13 -7 18 -22 17 -13 0 -26 -5 -30 -11 -5 -6 -8 -5 -8 3 0 9 -5 10 -19 3
-10 -6 -21 -7 -25 -4 -3 3 -6 -1 -6 -10 0 -8 6 -18 13 -20 6 -3 -2 -6 -19 -6
-48 -1 -191 -50 -284 -96 -240 -120 -398 -305 -491 -575 -33 -94 -33 -97 -33
-275 0 -175 1 -183 33 -285 33 -107 96 -237 134 -277 11 -13 15 -23 8 -23 -6
0 -14 7 -17 15 -4 8 -10 12 -15 9 -5 -3 -9 -2 -8 3 3 18 -2 31 -21 53 -11 13
-20 29 -20 37 0 7 -5 13 -12 13 -6 0 -9 3 -5 6 3 4 -4 27 -17 53 -13 25 -29
65 -35 89 -7 25 -18 42 -26 42 -20 0 -32 50 -15 60 8 5 10 15 5 26 -5 11 -7
22 -7 27 1 4 -4 7 -12 7 -11 0 -11 9 -3 46 9 37 8 47 -4 55 -10 6 -10 9 -1 9
8 0 12 20 12 65 0 36 7 100 16 142 8 41 13 82 10 90 -3 8 -1 11 4 8 6 -3 10 1
10 10 0 8 11 38 25 65 14 27 25 54 25 59 0 5 19 43 43 84 56 99 126 182 212
252 21 17 47 38 59 48 11 11 38 28 59 38 20 11 37 25 37 31 0 6 3 9 6 5 4 -3
23 2 43 12 20 10 61 27 91 37 67 22 59 20 98 29 17 4 32 12 32 17 0 6 -10 7
-22 4 -13 -4 -30 -8 -38 -10 -8 -2 -34 -11 -57 -20 -24 -9 -62 -23 -85 -32
-52 -19 -98 -46 -98 -56 0 -4 -3 -7 -7 -6 -8 2 -54 -21 -63 -31 -3 -3 -21 -18
-40 -32 -19 -15 -55 -45 -79 -68 -28 -27 -50 -40 -60 -36 -8 3 -13 3 -10 0 4
-3 -7 -20 -24 -38 -74 -78 -162 -245 -195 -367 -66 -244 -46 -489 59 -709 52
-110 178 -285 194 -269 3 2 -6 15 -20 28 -23 22 -24 25 -8 42 9 10 12 18 6 18
-5 0 -10 7 -10 15 0 20 2 19 22 -11 13 -20 14 -28 4 -40 -10 -12 -4 -22 34
-57 61 -56 87 -78 87 -72 0 3 9 -4 21 -16 11 -11 27 -18 35 -15 8 3 13 2 11
-2 -3 -4 1 -13 9 -20 8 -7 14 -10 14 -7 0 3 9 -4 20 -15 12 -12 25 -18 30 -15
6 4 10 1 10 -5 0 -6 5 -8 10 -5 6 3 10 2 10 -4 0 -5 9 -8 20 -6 11 2 18 0 16
-4 -6 -10 42 -33 54 -26 4 3 10 -1 13 -9 4 -10 -15 -15 -86 -19 -51 -4 -142
-14 -203 -23 -123 -19 -115 -20 -187 43 -42 37 -49 73 -15 73 12 0 36 15 53
34 l32 35 -67 76 c-79 90 -152 210 -193 314 -16 42 -34 81 -39 86 -8 7 -9 6
-5 -1 4 -7 0 -27 -8 -45 -12 -29 -14 -31 -15 -11 0 13 -7 30 -15 38 -13 13
-17 13 -38 -1 -13 -8 -26 -13 -29 -10 -3 3 -3 -7 -1 -22 4 -22 2 -25 -7 -13
-8 11 -9 6 -4 -25 4 -22 11 -48 15 -57 12 -23 11 -65 -2 -73 -18 -12 -23 4
-10 29 15 28 8 46 -19 46 -25 0 -36 18 -16 26 23 9 20 44 -3 44 -25 0 -37 18
-22 33 18 18 13 47 -9 47 -11 0 -20 7 -20 15 0 8 5 15 10 15 20 0 11 48 -10
53 -23 6 -27 27 -5 27 23 0 18 38 -7 51 -20 11 -20 13 -5 24 21 15 22 55 2 55
-20 0 -19 20 2 35 20 15 12 40 -16 48 -17 4 -19 1 -16 -22 2 -14 6 -64 9 -111
13 -203 83 -410 201 -595 43 -67 173 -224 209 -252 14 -12 26 -24 26 -27 0 -4
-35 -6 -77 -4 -75 3 -78 4 -81 28 -6 53 -12 67 -35 77 -19 9 -25 21 -29 58 -7
58 -15 66 -69 70 l-44 4 38 -8 c21 -4 43 -14 50 -21 10 -10 7 -11 -13 -6 -14
3 -43 8 -65 11 -39 5 -38 5 8 -8 62 -18 79 -34 46 -42 -15 -4 -25 -14 -26 -26
-1 -15 7 -20 38 -25 41 -7 89 -37 77 -49 -3 -4 -15 -2 -26 4 -12 6 -29 11 -39
11 -12 -1 -8 -5 12 -15 17 -7 23 -14 15 -14 -9 -1 -16 -13 -17 -33 l-2 -31
242 8 c133 4 258 7 277 8 19 0 121 6 225 14 105 7 201 14 215 14 20 1 18 3
-10 9 -47 10 351 10 515 0 72 -4 189 -15 260 -23 139 -16 285 -28 248 -20 -13
2 -23 7 -23 10 0 3 26 32 58 63 31 32 78 84 103 116 53 68 149 212 149 225 0
5 11 32 25 61 14 28 36 94 50 147 15 53 31 112 36 131 17 58 23 306 10 418
-12 98 -54 272 -87 356 -22 58 -107 197 -166 274 -211 277 -511 454 -838 496
-143 18 -260 21 -260 6z m100 -31 c0 -10 -6 -19 -14 -19 -19 0 -28 14 -20 33
8 21 34 10 34 -14z m-163 5 c3 -9 0 -20 -8 -25 -14 -8 -31 18 -22 34 7 12 23
8 30 -9z m243 -4 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20
13 0 20 -7 20 -20z m78 -9 c-5 -24 -38 -27 -38 -3 0 10 3 21 6 25 11 11 35 -5
32 -22z m-398 -6 c0 -16 -6 -25 -15 -25 -8 0 -15 4 -15 9 0 5 -3 16 -6 25 -4
12 0 16 15 16 16 0 21 -6 21 -25z m478 -2 c-5 -26 -38 -29 -38 -4 0 15 6 21
21 21 14 0 19 -5 17 -17z m76 -28 c-10 -25 -34 -17 -34 10 0 22 3 24 20 15 11
-6 17 -16 14 -25z m73 -11 c7 -20 -8 -36 -25 -25 -14 8 -6 41 9 41 5 0 12 -7
16 -16z m76 -20 c9 -10 -4 -34 -19 -34 -8 0 -14 6 -14 13 0 22 19 35 33 21z
m74 -40 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m66 -57
c-13 -24 -37 -5 -28 23 6 20 9 21 22 7 10 -10 12 -20 6 -30z m72 -17 c10 -16
-16 -43 -28 -31 -11 11 -2 41 12 41 5 0 13 -5 16 -10z m68 -66 c-6 -16 -29
-19 -38 -4 -4 6 -1 17 5 26 15 18 42 0 33 -22z m60 -50 c-6 -16 -29 -19 -38
-4 -4 6 -1 17 5 26 15 18 42 0 33 -22z m-569 -54 c92 -26 251 -111 328 -175
159 -132 297 -360 334 -550 11 -57 13 -62 11 -25 -1 38 2 45 18 48 17 3 17 1
5 -7 -13 -9 -12 -11 5 -12 l20 0 -20 -9 -20 -9 20 -1 20 -1 -20 -9 -20 -9 20
0 c17 -1 17 -3 5 -11 -12 -8 -12 -10 5 -11 l20 0 -20 -9 -20 -9 20 -1 20 -1
-20 -9 c-17 -7 -17 -9 -2 -9 20 -1 23 -27 5 -34 -9 -4 -8 -6 3 -6 11 -1 14 -9
11 -31 -2 -16 -9 -30 -16 -30 -6 0 -4 -5 4 -10 12 -8 12 -10 -5 -11 l-20 0 20
-9 c13 -6 15 -9 5 -9 -13 -1 -13 -2 0 -11 12 -8 12 -10 -5 -11 l-20 0 20 -9
20 -9 -20 -1 -20 -1 20 -9 c13 -6 15 -9 5 -9 -13 -1 -13 -2 0 -11 12 -8 12
-10 -5 -11 l-20 0 20 -9 c18 -8 18 -9 -5 -10 -23 -1 -23 -2 -5 -10 l20 -9 -20
-1 -20 -1 20 -9 c20 -8 20 -9 -1 -9 -20 -1 -21 2 -18 152 l2 152 -8 -140 c-12
-210 -55 -339 -169 -505 -35 -52 -65 -106 -65 -120 -1 -18 -7 -25 -23 -26 -12
-1 -59 -31 -105 -66 -126 -98 -226 -148 -365 -183 -65 -16 -98 -12 -89 12 3 8
6 75 6 149 l0 134 39 -26 c55 -36 123 -54 205 -54 89 0 139 13 197 52 80 54
127 145 143 276 l7 52 -34 0 c-19 0 -55 -3 -80 -6 l-47 -7 -6 -49 c-7 -54 -34
-100 -77 -132 -23 -17 -43 -21 -105 -21 -91 0 -128 20 -165 88 -21 40 -22 54
-25 330 -3 271 -2 287 15 289 10 1 90 2 178 2 l160 1 0 95 c0 94 0 95 -28 108
-18 9 -26 19 -23 28 7 18 6 18 -54 -6 -28 -11 -63 -20 -78 -20 -19 0 -7 9 46
30 86 35 114 37 182 10 28 -11 58 -20 66 -20 9 0 28 27 46 66 36 77 39 72 -75
109 -100 32 -123 31 -245 -5 -118 -35 -155 -37 -181 -11 -23 23 -26 24 -108
22 l-63 -1 0 -100 c0 -60 -4 -100 -10 -100 -6 0 -10 40 -10 99 l0 99 -67 4
c-43 2 -72 0 -78 -7 -36 -41 -85 -44 -186 -10 -119 41 -159 42 -271 5 -54 -17
-98 -33 -98 -35 0 -18 67 -135 77 -135 7 0 36 9 65 20 70 27 93 25 180 -10 48
-19 64 -30 48 -30 -14 0 -48 -14 -77 -30 -69 -40 -105 -100 -107 -178 l-1 -57
83 -3 c88 -3 112 5 112 39 0 17 8 19 80 19 l80 0 0 -268 c0 -308 -7 -350 -65
-401 -19 -16 -51 -33 -70 -37 -55 -10 -133 1 -163 23 -38 28 -72 91 -72 135 0
20 -4 39 -9 42 -5 4 -43 9 -85 13 l-76 6 5 -29 c2 -16 7 -49 10 -74 7 -59 48
-147 88 -188 18 -19 55 -46 82 -60 46 -25 60 -27 165 -27 125 0 157 10 230 72
32 27 33 27 28 5 -3 -12 -2 -22 3 -22 5 0 9 -60 9 -133 0 -90 4 -137 12 -145
20 -20 -97 -14 -181 9 -79 22 -214 86 -290 137 -57 39 -153 133 -206 203 -121
156 -197 373 -196 560 1 183 58 374 148 500 18 26 33 51 33 57 0 6 37 49 81
97 84 88 203 175 301 220 171 78 471 104 642 55z m629 16 c8 -21 -17 -40 -31
-23 -9 10 -8 17 0 25 16 16 25 15 31 -2z m53 -57 c10 -17 -13 -36 -27 -22 -12
12 -4 33 11 33 5 0 12 -5 16 -11z m-2082 -295 c14 -5 12 -34 -3 -34 -16 1 -33
19 -27 29 8 13 9 13 30 5z m1980 -65 c-3 -5 0 -9 6 -9 6 0 8 -4 5 -10 -3 -5
-1 -10 6 -10 7 0 9 -3 6 -7 -8 -7 -41 26 -44 45 -2 6 4 9 12 6 8 -4 12 -10 9
-15z m48 -88 c14 -17 14 -20 2 -25 -9 -3 -12 -1 -9 4 3 6 2 10 -3 10 -5 0 -9
7 -9 15 0 19 -1 19 19 -4z m28 -35 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6
10 25 14 25 6z m-410 -116 l0 -70 -207 -2 -208 -3 -5 -545 -5 -545 -70 0 -70
0 -3 548 -2 547 -126 0 c-132 0 -164 -8 -164 -40 0 -18 -7 -20 -73 -20 l-74 0
7 44 c7 45 30 90 59 114 49 40 61 41 509 42 l432 0 0 -70z m427 54 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m3 -18 c0 -2 -7 -7 -16 -10 -8 -3
-12 -2 -9 4 6 10 25 14 25 6z m17 -42 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m0 -20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m6 -21 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1943 -58 c0
-8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m1935 -454
c3 -5 1 -13 -5 -16 -15 -9 -43 3 -35 15 8 13 32 13 40 1z m-2068 -93 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 0 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m1988 -46 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m-8 -20 c-14 -3 -16 -7 -6 -18 9 -11 8 -15 -5 -20 -9 -3 -13
-10 -10 -15 3 -5 -3 -6 -12 -2 -11 4 -13 8 -6 11 7 2 10 8 7 13 -4 5 2 6 12 3
16 -5 16 -4 -1 10 -11 9 -14 16 -7 16 7 0 10 4 7 9 -4 5 4 6 16 3 19 -5 20 -7
5 -10z m-27 -78 c0 -2 -9 0 -20 6 -11 6 -20 13 -20 16 0 2 9 0 20 -6 11 -6 20
-13 20 -16z m-15 -25 c-4 -5 -11 -9 -17 -9 -6 0 -8 3 -5 7 4 3 1 13 -6 21 -10
13 -9 14 11 3 12 -7 20 -16 17 -22z m-40 -17 c-3 -3 -11 0 -18 7 -9 10 -8 11
6 5 10 -3 15 -9 12 -12z m-1925 -36 c-7 -7 -14 -6 -22 2 -10 10 -10 15 2 22
17 11 34 -10 20 -24z m43 -50 c6 -16 -19 -30 -28 -16 -7 11 2 30 15 30 4 0 10
-6 13 -14z m375 -21 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z
m1765 -20 c-3 -8 -9 -15 -14 -15 -10 0 -12 24 -3 33 11 11 24 -3 17 -18z
m-1722 -11 c13 -14 17 -23 9 -19 -13 5 -46 45 -37 45 2 0 14 -11 28 -26z
m-376 -15 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m246
-56 c5 -13 4 -25 -1 -28 -6 -4 -10 0 -10 8 0 8 -9 22 -20 32 -24 22 -66 13
-75 -15 -3 -11 -11 -20 -16 -20 -22 0 5 48 34 59 29 11 78 -9 88 -36z m199 12
c19 -22 8 -25 -12 -3 -10 10 -14 18 -9 18 5 0 14 -7 21 -15z m-397 -38 c-2 -4
-11 -3 -19 4 -12 10 -12 14 -2 20 13 9 30 -10 21 -24z m1957 -15 c0 -13 -12
-22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-1430 -38 c0 -2 -9 0 -20 6
-11 6 -20 13 -20 16 0 2 9 0 20 -6 11 -6 20 -13 20 -16z m-479 3 c-1 -12 -15
-9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m56 -63 c-9 -9 -28 6 -21 18 4 6
10 6 17 -1 6 -6 8 -13 4 -17z m1743 -13 c0 -12 -20 -25 -27 -18 -7 7 6 27 18
27 5 0 9 -4 9 -9z m-1682 -39 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0 13 23
5 28 -10z m1622 -6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6
0 10 -2 10 -4z"/>
<path d="M3255 3030 c-14 -27 -22 -50 -17 -50 9 0 19 19 41 73 18 44 3 31 -24
-23z"/>
<path d="M2047 2248 c8 -67 22 -104 50 -132 35 -35 54 -33 21 2 -32 33 -48 72
-48 116 0 19 -6 39 -14 45 -12 10 -13 5 -9 -31z"/>
<path d="M2370 1905 c-14 -8 -20 -14 -14 -15 5 0 19 7 30 15 24 18 16 19 -16
0z"/>
<path d="M2868 1873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2530 3660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2390 3591 c-8 -6 -18 -7 -22 -5 -4 3 -8 -1 -8 -9 0 -10 8 -13 25 -9
14 2 25 11 25 18 0 16 -1 17 -20 5z"/>
<path d="M3315 3460 c-16 -18 -21 -55 -6 -46 5 3 16 -5 25 -19 34 -52 109 -11
87 48 -8 20 -18 26 -51 29 -29 2 -45 -1 -55 -12z"/>
<path d="M1670 3450 c-22 -22 -20 -45 8 -87 16 -25 33 -29 64 -12 14 8 17 13
9 18 -9 6 -9 15 -1 36 12 32 2 48 -37 59 -15 5 -28 1 -43 -14z"/>
<path d="M1800 1965 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M3390 1870 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M1278 1853 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3763 1853 c26 -2 68 -2 95 0 26 2 4 3 -48 3 -52 0 -74 -1 -47 -3z"/>
<path d="M3378 1843 c-10 -2 -18 -8 -18 -13 0 -5 -8 -16 -17 -25 -12 -13 -14
-21 -6 -34 6 -9 14 -16 19 -16 5 0 14 -4 21 -8 18 -11 62 3 73 23 29 54 -7 90
-72 73z"/>
<path d="M3642 1827 c-15 -16 -22 -36 -22 -63 0 -32 -6 -46 -30 -69 -22 -21
-30 -38 -30 -62 0 -31 2 -33 34 -33 33 0 34 0 26 35 -5 23 -4 36 4 39 6 2 0 5
-13 5 -20 1 -21 3 -11 16 7 8 27 19 44 23 35 9 49 48 22 58 -25 10 -19 21 22
38 l37 17 -34 -4 c-31 -4 -32 -3 -16 10 15 10 16 13 4 13 -9 0 -25 -10 -37
-23z"/>
<path d="M3958 1825 c30 -14 61 -25 70 -25 20 1 -86 49 -108 50 -8 0 9 -11 38
-25z"/>
<path d="M3795 1830 c11 -5 31 -9 45 -9 23 0 23 1 5 9 -11 5 -31 9 -45 9 -23
0 -23 -1 -5 -9z"/>
<path d="M1135 1805 c-37 -17 -46 -25 -33 -30 19 -8 73 18 81 40 4 8 5 15 4
14 -1 0 -24 -11 -52 -24z"/>
<path d="M3110 1811 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M961 1791 c-10 -7 -11 -15 -3 -37 6 -18 11 -22 11 -11 1 13 10 17 44
18 23 1 33 3 22 6 -11 2 -31 7 -45 10 -24 6 -23 7 10 13 34 7 34 7 5 9 -16 0
-36 -3 -44 -8z"/>
<path d="M1258 1788 c-3 -7 -2 -23 3 -35 12 -32 41 -19 37 16 -3 27 -32 41
-40 19z"/>
<path d="M4085 1790 l30 -8 -32 -1 c-18 0 -33 -6 -33 -12 0 -8 14 -10 41 -6
38 5 41 4 35 -16 -14 -52 -18 -187 -7 -256 13 -84 10 -91 -46 -91 -18 0 -33
-4 -33 -10 0 -5 -22 -10 -50 -10 -27 0 -50 -3 -50 -7 0 -11 193 -3 206 8 9 7
9 21 -2 54 -21 72 -26 155 -14 230 7 39 14 85 17 103 l5 32 -48 -1 c-43 -1
-46 -2 -19 -9z"/>
<path d="M1179 1774 c-10 -13 -10 -17 2 -25 25 -15 39 -10 39 16 0 28 -21 33
-41 9z"/>
<path d="M3725 1782 c-3 -3 -5 -16 -5 -29 0 -16 6 -23 20 -23 15 0 20 7 20 28
0 26 -20 39 -35 24z"/>
<path d="M3870 1765 c0 -26 22 -33 49 -16 12 8 12 12 2 25 -21 26 -51 20 -51
-9z"/>
<path d="M4018 1773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1175 1710 c3 -5 18 -10 33 -9 24 0 25 1 7 9 -28 12 -47 12 -40 0z"/>
<path d="M3881 1709 c-18 -6 -31 -12 -29 -14 7 -8 78 7 78 16 0 11 -4 10 -49
-2z"/>
<path d="M1293 1693 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3698 1693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1245 1666 c-22 -16 -15 -41 14 -49 18 -4 21 -1 21 23 0 35 -11 44
-35 26z"/>
<path d="M1320 1640 c0 -33 3 -40 20 -40 16 0 20 7 20 34 0 22 -6 36 -16 40
-24 9 -24 9 -24 -34z"/>
<path d="M3807 1643 c-7 -36 -4 -38 33 -23 37 15 37 44 -1 48 -22 3 -27 -2
-32 -25z"/>
<path d="M3660 1625 c0 -28 4 -35 20 -35 16 0 20 7 20 35 0 28 -4 35 -20 35
-16 0 -20 -7 -20 -35z"/>
<path d="M1232 1588 c7 -22 58 -40 123 -46 66 -5 274 13 322 28 19 7 6 7 -42
3 -38 -3 -128 -6 -200 -7 -105 -1 -136 2 -164 17 -41 20 -44 21 -39 5z"/>
<path d="M3255 1590 c47 -13 376 -50 447 -50 69 0 123 12 153 34 31 22 11 29
-27 9 -27 -15 -55 -17 -168 -16 -74 0 -195 8 -268 17 -145 17 -184 19 -137 6z"/>
<path d="M2218 1508 l-21 -21 21 -8 c31 -12 44 -52 36 -110 -6 -45 -5 -49 14
-49 16 0 22 9 27 42 5 25 23 63 46 93 43 57 48 75 21 75 -18 0 -68 -57 -59
-66 3 -3 15 9 27 26 12 17 24 29 27 26 3 -3 -10 -27 -30 -53 -19 -27 -38 -65
-42 -86 -11 -55 -28 -59 -20 -4 8 52 -9 117 -31 117 -8 0 -14 5 -14 10 0 6 9
10 19 10 13 0 23 -11 31 -35 6 -19 16 -35 22 -35 5 0 8 3 6 8 -3 4 -10 21 -17
39 -16 41 -36 48 -63 21z"/>
<path d="M2446 1514 c-25 -25 -19 -37 14 -30 35 6 35 8 14 -80 -10 -43 -12
-70 -5 -77 15 -15 30 1 41 46 6 23 16 59 21 80 10 39 26 46 58 25 18 -11 18
-13 1 -79 -10 -37 -15 -70 -12 -73 3 -4 63 -6 134 -6 121 0 128 1 128 20 0 16
-7 20 -30 20 -40 0 -45 16 -26 81 29 95 39 89 -153 89 -141 0 -172 -3 -185
-16z m144 -3 c0 -6 -14 -11 -32 -13 -31 -3 -33 -5 -51 -80 -11 -43 -23 -78
-27 -78 -5 0 -2 24 5 53 29 118 30 110 -5 105 -30 -4 -42 6 -17 15 22 9 127 7
127 -2z m149 -7 c2 -2 -19 -4 -47 -4 -38 0 -52 -4 -52 -14 0 -8 -3 -21 -6 -30
-5 -13 1 -16 31 -16 21 0 34 -4 30 -10 -3 -5 -21 -10 -40 -10 -27 0 -34 -4
-39 -26 -9 -34 -1 -42 49 -47 32 -3 29 -4 -17 -5 -32 -1 -58 1 -58 6 0 4 7 32
15 62 17 64 19 94 5 85 -5 -3 -10 -4 -10 0 0 19 119 26 139 9z m30 -61 c-10
-38 -19 -73 -19 -80 0 -7 18 -14 43 -16 37 -3 35 -4 -15 -5 l-56 -2 14 48 c8
26 19 64 25 85 5 20 14 37 19 37 5 0 0 -30 -11 -67z m-22 33 c1 -1 -5 -25 -13
-54 l-16 -52 -44 0 c-38 0 -45 3 -42 18 2 12 14 18 41 20 29 3 37 7 37 23 0
14 -7 19 -30 19 -28 0 -40 17 -23 33 5 5 81 -1 90 -7z"/>
<path d="M2097 1483 c-13 -21 -36 -59 -51 -85 -27 -47 -63 -67 -52 -30 2 9 7
37 11 62 4 25 9 53 11 63 5 15 -1 17 -51 13 -31 -2 -59 -6 -62 -10 -11 -11 7
-38 22 -32 8 3 22 3 30 0 24 -10 1 -124 -25 -124 -6 0 -10 7 -10 15 0 8 -9 15
-20 15 -14 0 -20 -7 -20 -24 0 -33 24 -50 57 -41 16 4 45 6 65 6 31 -1 40 3
54 29 25 42 68 42 83 0 11 -32 28 -39 37 -15 8 19 -35 195 -47 195 -5 0 -19
-17 -32 -37z m-102 -32 c-13 -94 -24 -119 -54 -129 -31 -11 -51 0 -51 28 1 12
3 12 15 -3 9 -13 20 -17 34 -12 22 7 26 16 36 84 8 48 -1 60 -45 61 -16 0 -21
3 -13 8 7 4 29 8 49 8 l36 0 -7 -45z m168 -88 c8 -42 -9 -43 -18 -2 -6 25 -11
30 -28 25 -12 -3 -29 -6 -37 -6 -9 0 -24 -13 -33 -30 -17 -29 -47 -41 -47 -20
0 6 6 10 13 10 7 0 24 19 37 43 13 23 35 60 49 82 l25 40 16 -55 c10 -30 20
-70 23 -87z"/>
<path d="M2096 1435 c-21 -32 -20 -35 9 -35 20 0 25 5 25 24 0 35 -15 40 -34
11z m24 -15 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z"/>
<path d="M2884 1499 c-15 -17 -16 -22 -4 -29 12 -8 12 -18 -3 -76 -10 -36 -17
-67 -15 -69 2 -1 31 -5 65 -9 55 -6 63 -5 72 12 14 26 5 32 -45 32 -37 0 -41
2 -37 19 4 14 15 21 41 23 26 2 38 8 40 21 3 13 -3 17 -28 17 -25 0 -31 4 -28
18 3 13 14 17 53 17 44 0 50 3 53 23 3 21 0 22 -71 22 -62 0 -77 -3 -93 -21z
m136 1 c19 -6 12 -8 -31 -9 -50 -1 -57 -4 -63 -23 -9 -35 -8 -36 27 -41 31 -4
31 -4 -5 -5 -23 -1 -38 -7 -38 -14 0 -7 -3 -23 -6 -35 -6 -21 -3 -23 40 -23
25 0 46 -4 46 -10 0 -6 -26 -10 -59 -10 l-59 0 23 81 c18 62 20 79 9 75 -8 -3
-14 -1 -14 3 0 20 81 27 130 11z"/>
<path d="M3105 1506 c-17 -7 -43 -28 -58 -45 -47 -56 -31 -142 29 -154 56 -10
132 29 106 55 -9 9 -18 8 -35 -1 -13 -7 -37 -11 -53 -9 -57 6 -39 91 26 118
34 14 49 8 32 -13 -15 -17 -6 -27 24 -27 46 0 52 64 7 81 -31 12 -42 11 -78
-5z m90 -37 c0 -16 -6 -25 -20 -27 -19 -3 -19 -2 -3 11 13 10 15 16 6 25 -30
30 -106 -5 -127 -57 -11 -26 -11 -34 3 -55 21 -33 49 -40 86 -21 16 8 30 12
30 7 0 -12 -46 -32 -73 -32 -103 0 -78 159 28 182 38 8 70 -7 70 -33z"/>
<path d="M3265 1480 c-32 -21 -65 -73 -65 -102 0 -48 42 -88 93 -88 30 0 82
33 104 66 26 40 23 102 -6 125 -30 25 -88 24 -126 -1z m113 -6 c17 -11 22 -25
22 -56 0 -56 -51 -108 -106 -108 -68 1 -96 42 -70 104 26 63 106 94 154 60z"/>
<path d="M3303 1473 c-12 -2 -34 -18 -48 -34 -66 -79 15 -160 93 -94 51 42 54
102 6 123 -14 7 -26 11 -27 11 -1 -1 -12 -4 -24 -6z m66 -32 c22 -40 -31 -111
-81 -111 -26 0 -50 35 -42 64 14 56 101 89 123 47z"/>
<path d="M1733 1454 l-21 -37 -13 29 c-16 32 -42 42 -64 24 -21 -18 -18 -40 5
-40 28 0 43 -43 37 -103 -4 -44 -2 -48 17 -45 16 2 22 13 28 45 4 23 23 67 42
98 20 30 36 58 36 60 0 3 -10 5 -23 5 -16 0 -29 -11 -44 -36z m26 -11 c-12
-21 -29 -47 -36 -58 -7 -11 -13 -34 -13 -52 0 -17 -5 -35 -12 -39 -8 -5 -9 5
-5 35 6 48 -18 121 -40 121 -8 0 -11 5 -8 10 13 21 34 9 45 -25 6 -19 15 -35
18 -35 4 0 18 18 32 40 14 22 29 40 33 40 5 0 -2 -17 -14 -37z"/>
<path d="M3455 1400 c-22 -39 -48 -73 -58 -76 -25 -8 -17 -38 10 -42 17 -3 29
8 52 45 l30 47 3 -49 c3 -43 6 -50 26 -53 12 -2 22 0 22 5 0 4 16 26 36 48 24
27 33 34 30 20 -19 -83 -19 -87 5 -83 21 3 25 12 36 73 17 102 16 125 -4 124
-18 0 -99 -96 -90 -105 2 -3 22 18 43 46 21 27 42 50 46 50 10 0 11 10 -7 -89
-18 -105 -30 -119 -21 -26 l7 70 -23 -25 c-13 -14 -37 -44 -55 -65 l-32 -40
-1 73 c0 39 -3 72 -7 72 -5 0 -23 -27 -42 -60 -34 -59 -55 -78 -66 -60 -4 6 0
10 7 10 8 0 34 34 58 75 24 41 48 75 52 75 5 0 8 -31 8 -70 0 -38 4 -70 9 -70
14 0 16 14 13 85 -3 59 -6 65 -25 65 -17 0 -32 -18 -62 -70z"/>
<path d="M1488 1378 c-58 -111 -71 -113 -62 -9 l7 82 -44 -6 c-63 -9 -69 -12
-69 -35 0 -17 6 -20 33 -18 l32 3 -3 -50 c-4 -55 -26 -92 -38 -62 -9 24 -44
22 -44 -2 0 -24 26 -51 48 -51 10 0 28 7 40 16 18 12 26 13 37 4 18 -15 51 2
60 31 6 18 41 39 65 39 5 0 11 -13 15 -30 6 -29 28 -40 40 -20 10 16 -46 190
-61 190 -7 0 -32 -37 -56 -82z m95 -50 c8 -27 11 -48 6 -48 -5 0 -11 11 -15
25 -5 20 -12 25 -38 25 -38 0 -51 -8 -60 -37 -8 -25 -35 -38 -50 -23 -7 7 -4
10 10 10 13 1 27 17 44 53 14 29 34 68 45 87 l19 34 12 -39 c7 -22 19 -61 27
-87z m-169 45 c-9 -92 -22 -118 -59 -118 -22 0 -31 5 -33 20 -4 19 -3 19 10 1
12 -15 18 -16 35 -7 16 8 23 25 28 71 4 34 5 63 2 66 -3 3 -19 6 -36 6 -38 1
-28 15 14 21 17 2 34 5 38 6 4 0 5 -29 1 -66z"/>
<path d="M1518 1380 c-19 -33 -16 -39 18 -32 20 4 23 9 18 26 -10 35 -18 37
-36 6z"/>
<path d="M3753 1442 c-24 -4 -53 -37 -53 -60 0 -8 14 -27 31 -43 26 -24 29
-32 19 -44 -18 -22 -51 -19 -57 5 -7 25 -36 23 -41 -3 -11 -54 79 -77 123 -32
27 26 31 46 15 75 -8 15 -6 21 10 30 15 8 20 19 18 37 -3 28 -28 41 -65 35z
m52 -36 c0 -11 -6 -21 -13 -24 -7 -2 -10 2 -6 12 8 21 -22 32 -41 16 -21 -17
-19 -23 15 -58 35 -37 37 -45 10 -72 -31 -31 -68 -36 -93 -12 -11 12 -17 25
-13 29 4 4 12 0 17 -10 11 -20 59 -23 77 -5 18 18 14 46 -8 58 -54 29 -37 94
23 88 24 -2 32 -8 32 -22z m-35 -10 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0
6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M1112 1404 c-19 -13 -37 -15 -94 -9 -57 6 -70 5 -66 -6 5 -17 80 -23
156 -14 41 5 51 9 43 19 -6 7 -8 16 -4 19 13 14 -13 7 -35 -9z"/>
<path d="M1777 1413 c-4 -3 -7 -13 -7 -20 0 -11 14 -13 57 -11 46 2 59 7 61
21 3 14 -5 17 -51 17 -29 0 -57 -3 -60 -7z m83 -13 c-20 -13 -70 -13 -70 0 0
6 19 10 43 10 31 0 38 -3 27 -10z"/>
<path d="M1160 1337 c0 -7 13 -24 29 -38 l30 -26 -1 28 c-1 22 -8 30 -30 38
-21 7 -28 7 -28 -2z"/>
<path d="M3905 1317 c-11 -12 -22 -32 -25 -46 -14 -66 -109 -87 -323 -71 -84
6 -174 15 -202 19 -27 4 -124 8 -215 9 -91 1 -198 7 -239 13 -41 6 -87 8 -103
5 -15 -3 -74 1 -131 8 -248 34 -550 28 -688 -13 -81 -24 -125 -29 -329 -38
-258 -12 -343 -9 -390 16 -40 21 -49 16 -30 -19 32 -60 240 -55 746 15 196 28
475 39 659 26 94 -6 244 -16 335 -22 91 -6 221 -17 290 -25 69 -7 197 -20 286
-28 252 -23 344 -1 344 83 0 11 11 28 25 37 14 9 25 25 25 35 0 24 -10 23 -35
-4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
